div.trabajo_wedrim_container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background-image: url(https://wedrim.sfo2.cdn.digitaloceanspaces.com/media/TRABAJA_CON_NOSOTROS.jpg);
    height: 95vh;
}


div.trabajo_wedrim_container::before {
    content: "";
    background-image: linear-gradient(to bottom, #25252530, #25252530);
    width: 100%;
    height: 84vh;
    position: absolute;
}

div.trabajo_wedrim_container div.contenido {
    width: 30%;
    align-self: center;
    margin-left: 4%;
    margin-bottom: 5%;
    padding: 1%;
    background-color: #00000040;
    border-radius: 5px;
    z-index: 10;
}

div.trabajo_wedrim_container h1 {
    font-family: 'Montserrat';
    font-weight: 500;
    margin-bottom: 2%;
    color: #ffffff;
}

div.trabajo_wedrim_container h3 {
    font-family: 'Montserrat';
    font-weight: 300;
    margin-bottom: 5%;
    color: #ffffff;
}

div.trabajo_wedrim_container h3.titulo_input {
    font-family: 'Montserrat';
    font-weight: 500;
    margin: 5% 5%;
}

div.contenido form {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  div.contenido form.form_contacto input, div.contenido form.form_contacto textarea {
    width: 100%;
    padding: 15px 15px;
    margin: 8px 0;
    border: 1px solid #ccc;
    background-color: rgb(211, 211, 211);
    border-radius: 4px;
  }

div.trabajo_wedrim_container div.contenido .input_msj {
    width: 100%;
    margin-bottom: 5%;
    color: #ffffff;
    font-weight: bolder;
}

div.trabajo_wedrim_container div.contenido .cv_btn {
    Font-family: "Montserrat";
    font-size: 18px;
    font-weight: 300;
    width: 50%;
    color: #ffffff;
    background-color: transparent;
    border-style: solid;
    border-radius: 100px 100px 100px 100px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-color: #ffffff;
    cursor: pointer;
    text-align: center;
}

div.trabajo_wedrim_container .contenido Button.button_cv {
    Font-family: "Montserrat";
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    background-color: transparent;
    border-style: solid;
    border-radius: 100px 100px 100px 100px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-width: thin;
    cursor: pointer;
    width: 100%;
    text-align: center;
    margin-bottom: 2%;
}

div.trabajo_wedrim_container .contenido button.btn_enviar_cv {
    Font-family: "Montserrat";
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    background-color: transparent;
    border-style: solid;
    border-radius: 100px 100px 100px 100px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-width: thin;
    cursor: pointer;
    width: 50%;
}

div.trabajo_wedrim_container .contenido button.enviar_cv {
    Font-family: "Montserrat";
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    background-color: transparent;
    border-style: solid;
    border-radius: 100px 100px 100px 100px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-width: thin;
    cursor: pointer;
    width: 50%;
}


div.trabajo_wedrim_container div.sobre_btn {
    display: flex;
    flex-direction: column;
    margin: 10% 0 0 2%;
    align-items: center;
}

div.sobre_btn h4 {
    font-family: 'Montserrat';
    font-weight: 500;
    margin-bottom: 20%;
}

/*Responsive*/

@media only screen and (max-width: 992px) {
    div.trabajo_wedrim_container div.contenido {
        width: 70%;
    }
}

@media only screen and (max-width: 768px) {

    div.trabajo_wedrim_container {
        height: 92vh;
    }
    
    div.trabajo_wedrim_container div.contenido {
        width: 80%;
    }
}

@media only screen and (max-width: 600px) {
    div.trabajo_wedrim_container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 92vh;
        background-position-x: 242vw;
    }

    div.trabajo_wedrim_container::before {
        height: 92vh;
    }
    div.trabajo_wedrim_container div.contenido {
        width: 90%;
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        margin-left: 0%;
    }
    div.trabajo_wedrim_container div.sobre_btn {
        display: none;
    }

    div.trabajo_wedrim_container div.contenido .cv_btn {
        width: 75%;
    }
    div.trabajo_wedrim_container div.contenido .input_msj {
        width: 80%;
        text-align: center;
    }

    div.trabajo_wedrim_container .contenido button.btn_enviar_cv {
        width: 100%;
    }
}