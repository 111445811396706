div.segundaVista_container {
    display: flex;
    flex-direction: row;
    height: 84vh;
    background-image: url(https://wedrim.sfo2.cdn.digitaloceanspaces.com/media/nuestro_compromiso_2.jpg);
    background-position-x: 104vw;
    background-position-y: 100vh;
}

div.segundaVista_container::before {
    content: "";
    background-image: linear-gradient(to bottom, #25252530, #25252530);
    width: 100%;
    height: 84vh;
    position: absolute;
}

div.segundaVista_container div.contenido {
    width: 30%;
    align-self: center;
    margin-left: 4%;
    z-index: 10;
    padding: 1%;
    background-color: #00000040;
    border-radius: 5px;
}

div.segundaVista_container h1 {
    font-family: 'Montserrat';
    font-weight: 500;
    margin-bottom: 2%;
    color: #ffffff;
}

div.segundaVista_container h3 {
    font-family: 'Montserrat';
    font-weight: 300;
    color: #ffffff;
}

div.segundaVista_container a {
    Font-family: "Montserrat";
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    background-color: #ffffff;
    border-style: solid;
    border-radius: 100px 100px 100px 100px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-color: #fff;
    cursor: pointer;
    width: 100%;
    text-align: center;
}

/*Responsive*/

@media only screen and (max-width: 992px) {
    div.segundaVista_container {
        height: 95vh;
        background-position-y: 306vh;
        background-position-x: 180vw;
    }
    div.segundaVista_container::before {
        height: 100vh;
    }
    div.segundaVista_container div.contenido {
        width: 45%;
    }
}

@media only screen and (max-width: 768px) {
    div.segundaVista_container {
        height: 92vh;
        background-size: cover;
        background-position-y: 184vh;
        background-position-x: 271vw;
    }
    div.segundaVista_container::before {
        height: 100vh;
    }
    div.segundaVista_container div.contenido {
        width: 60%;
    }
    div.segundaVista_container h1, div.segundaVista_container h3 {
        color: #fff;
    }
}

@media only screen and (max-width: 600px) {
    div.segundaVista_container {
        height: 100vh;
        background-size: cover;
        background-position-x: center;
        background-position-y: center;
    }
    div.segundaVista_container::before {
        height: 100vh;
    }
    div.segundaVista_container div.contenido {
        width: 90%;
    }
    div.segundaVista_container h1, div.segundaVista_container h3 {
        color: #fff;
    }
}