div.Barra_Redes_container {
  display: flex;
  align-items: center;
  height: 100vh;
  right: 0%;
  position: fixed;
  z-index: 10;
}

div.Barra_Redes_container div.contenido {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 30vh;
  width: 3vw;
  background-color: rgba(0, 0, 0, 0.500);
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

div.Barra_Redes_container div.contenido .icono_redes {
  cursor: pointer;
  font-size: 30px;
  color: #fff;
}

/*Responsive*/

@media only screen and (max-width: 992px) {
  div.Barra_Redes_container {
    margin-left: 3%;
  }

  div.Barra_Redes_container div.contenido {
    width: 6vw;
  }
}

@media only screen and (max-width: 768px) {
  div.Barra_Redes_container {
    height: 100vh;
    margin-left: 3%;
  }

  div.Barra_Redes_container div.contenido {
    width: 6vw;
  }
}

@media only screen and (max-width: 600px) {
  div.Barra_Redes_container {
    display: none;
  }
}