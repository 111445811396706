div.pymes_container {
    display: flex;
    flex-direction: row;
    height: 95vh;
    background-image: url(https://wedrim.sfo2.cdn.digitaloceanspaces.com/media/STARTUP.jpeg);
    background-position-y: 172vh;
    background-position-x: 244vw;
}

div.pymes_container div.contenido {
    width: 30%;
    align-self: center;
    margin-left: 4%;
    padding: 1%;
    background-color: #00000040;
    border-radius: 5px;
}

div.pymes_container h1 {
    font-family: 'Montserrat';
    font-weight: 500;
    margin-bottom: 2%;
    color: #ffffff;
}

div.pymes_container h3 {
    font-family: 'Montserrat';
    font-weight: 300;
    color: #ffffff;
}

div.pymes_container a {
    Font-family: "Montserrat";
    font-size: 18px;
    font-weight: 500;
    color: #000000;
    background-color: #ffffff;
    border-style: solid;
    border-radius: 100px 100px 100px 100px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-color: #000000;
    cursor: pointer;
    width: 100%;
    text-align: center;
}

/*Responsive*/

@media (min-width: 2500px) and (max-width: 3000px) {
    div.pymes_container {
        background-size: 100vw 100%;
        background-position-y: unset;
        background-position-x: unset;
        background-repeat: no-repeat;
    }
}

@media only screen and (max-width: 992px) {
    div.pymes_container {
        height: 140vh;
        background-position-y: 151vh;
        background-position-x: 263vw;
        background-size: cover;
    }
    div.pymes_container div.contenido {
        width: 50%;
    }
}

@media only screen and (max-width: 768px) {
    div.pymes_container {
        height: 140vh;
        background-position-y: 151vh;
        background-position-x: 263vw;
        background-size: cover;
    }
    div.pymes_container div.contenido {
        width: 50%;
        margin-top: 25%;
    }
}

@media only screen and (max-width: 600px) {
    div.pymes_container {
        height: 130vh;
        background-image: url(https://wedrim.sfo2.cdn.digitaloceanspaces.com/media/SERVICIOS.jpg);
        background-position-y: 139vh;
        background-position-x: -165vw;
        background-size: cover;
    }
    div.pymes_container div.contenido {
        width: 90%;
        margin-left: 4%;
        z-index: 10;
    }
}