div.educativas_container {
    display: flex;
    flex-direction: row;
    height: 100vh;
    background-image: url(https://wedrim.sfo2.cdn.digitaloceanspaces.com/media/EDUCACION_1.jpg);
}

div.educativas_container div.contenido {
    width: 30%;
    align-self: center;
    margin-left: 4%;
    padding: 1%;
    background-color: #00000040;
    border-radius: 5px;
}

div.educativas_container h1 {
    font-family: 'Montserrat';
    font-weight: 500;
    margin-bottom: 2%;
    color: #ffffff;
}

div.educativas_container h3 {
    font-family: 'Montserrat';
    font-weight: 300;
    color: #fff;

    /*text-shadow: -1px -1px 1px #000, 1px -1px 1px #000, -1px 1px 1px #000, 1px 1px 1px #000;*/
}

div.educativas_container a {
    Font-family: "Montserrat";
    font-size: 18px;
    font-weight: 500;
    color: #000000;
    background-color: #ffffff;
    border-style: solid;
    border-radius: 100px 100px 100px 100px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-color: #000000;
    cursor: pointer;
    width: 100%;
    text-align: center;
}

/*Responsive*/

@media only screen and (max-width: 992px) {

    div.educativas_container {
        background-position-x: 160vw;
        background-position-y: 252vh;
    }

    div.educativas_container div.contenido {
        width: 45%;
        margin-top: 15%;
    }

}

@media only screen and (max-width: 768px) {

    div.educativas_container {
        background-position-x: 160vw;
        background-position-y: 252vh;
    }

    div.educativas_container div.contenido {
        width: 45%;
    }
   
}

@media only screen and (max-width: 600px) {
    div.educativas_container{
        background-size: cover;
        background-position-x: 150vw ;
        background-position-y: 100vh;
    }

    div.educativas_container div.contenido {
        width: 90%;
    }

    div.educativas_container {
        height: 100vh;
    }
    
}