div.alianzas_container {
    background-image: url(https://wedrim.sfo2.cdn.digitaloceanspaces.com/media/SERVICIOS.jpg);
    background-position-y: 250vh;
    display: flex;
    flex-direction: row;
    height: 95vh;
}

div.alianzas_container div.contenido {
    width: 90%;
    align-self: center;
    margin-left: 4%;
}

div.alianzas_container h1 {
    font-family: 'Montserrat';
    font-weight: 500;
    margin-bottom: 2%;
    color: #fff;
}

div.alianzas_container h3 {
    font-family: 'Montserrat';
    font-weight: 300;
    width: 30%;
    color: #fff;
}

div.alianzas_container div.contenido div.container_tarjetas {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    overflow: hidden;
}

div.alianzas_container div.contenido div.container_tarjetas span.fila {
    display: flex;
    flex-direction: row;
    margin-bottom: 5%;
    align-items: center;
}

/*Tarjeta*/

div.tarjeta {
    padding: 2%;
    text-align-last: center;
    align-self: center;
}

div.tarjeta img {
    width: 15vh;
}

div.tarjeta div.texto {
    text-align: center;
}

div.tarjeta div.texto p {
    font-family: 'Montserrat';
    font-weight: 300;
    color: #ffffff;
}

/*Responsive*/

@media only screen and (max-width: 992px) {
    div.alianzas_container {
        height: 95vh;
        margin-left: 0%;
        background-position-y: 0vh;
    }
    div.alianzas_container div.contenido {
        text-align: center;
    }
    div.alianzas_container h3 {
        width: 100%;
    }
    div.tarjeta img {
        width: 80%;
    }
    div.alianzas_container div.contenido div.container_tarjetas span.fila {
        display: flex;
        flex-direction: row;
    }
    div.alianzas_container div.contenido div.container_tarjetas {
        display: flex;
        flex-direction: row;
    }
}

@media only screen and (max-width: 768px) {
    div.alianzas_container {
        height: 92vh;
        margin-left: 0%;
        background-position-y: 0vh;
    }
    div.alianzas_container div.contenido {
        text-align: center;
    }
    div.alianzas_container h3 {
        width: 100%;
    }
    div.tarjeta img {
        width: 80%;
    }
    div.alianzas_container div.contenido div.container_tarjetas span.fila {
        display: flex;
        flex-direction: row;
    }
    div.alianzas_container div.contenido div.container_tarjetas {
        display: flex;
        flex-direction: row;
    }
}

@media only screen and (max-width: 600px) {
    div.alianzas_container {
        height: 92vh;
        margin-left: 0%;
    }
    div.alianzas_container div.contenido {
        width: 100%;
        text-align: center;
        align-self: normal;
        margin-top: 25%;
        margin-left: 0%;
        overflow: scroll;
    }
    div.alianzas_container div.contenido div.container_tarjetas {
        display: flex;
    }
    div.alianzas_container div.contenido div.container_tarjetas_movil {
        display: flex;
        flex-direction: column;
    }
    div.alianzas_container div.contenido div.container_tarjetas span.fila {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        margin-bottom: 5%;
        justify-content: center;
    }
    div.alianzas_container div.contenido div.container_tarjetas {
        width: 100%;
    }

    div.tarjeta{
        padding: 1%;
        margin: 10% 0 10% 0;
    }

    div.tarjeta img {
        width: 50%;
    }
}