div.compromiso_container {
    display: flex;
    flex-direction: row;
    height: 100vh;
    background-image: url(https://wedrim.sfo2.cdn.digitaloceanspaces.com/media/nuestro_compromiso_1.jpg);
    background-position-y: 106vh;
}

div.compromiso_container div.contenido {
    width: 30%;
    align-self: center;
    margin-left: 4%;
    padding: 1%;
    background-color: #00000040;
    border-radius: 5px;
}

div.compromiso_container h1 {
    font-family: 'Montserrat';
    font-weight: 500;
    margin-bottom: 2%;
    color: #fff;
}

div.compromiso_container h3 {
    font-family: 'Montserrat';
    font-weight: 300;
    color: #ffffff;
}

div.compromiso_container a {
    Font-family: "Montserrat";
    font-size: 18px;
    font-weight: 500;
    color: #ffffff;
    background-color: #ffffff;
    border-style: solid;
    border-radius: 100px 100px 100px 100px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-color: #ffffff;
    cursor: pointer;
    width: 100%;
    text-align: center;
}


/*Responsive*/

@media only screen and (max-width: 992px) {
    div.compromiso_container {
        height: 100vh;
        background-position-y: 306vh;
        background-position-x: 160vh;
    }
    div.compromiso_container div.contenido {
        width: 45%;
    }
}

@media only screen and (max-width: 768px) {
    div.compromiso_container {
        height: 100vh;
        background-position-y: 137vh;
        background-position-x: 178vw;
    }
    div.compromiso_container div.contenido {
        width: 45%;
        margin-top: 15%;
    }
    div.compromiso_container h1, div.compromiso_container h3 {
        color: #fff;
    }
}

@media only screen and (max-width: 600px) {

    div.compromiso_container {
        background-size: cover;
        background-position-x: 170vw;
        background-position-y: 210vh;
    }

    div.compromiso_container div.contenido {
        width: 90%;
        margin-left: 4%;
    }
}