div.TerceraVista_container {
    background-image: url(https://wedrim.sfo2.cdn.digitaloceanspaces.com/media/elegirnos-3.jpg);
    background-position-y: 250vh;
    display: flex;
    flex-direction: row;
    height: 84vh;
}

div.TerceraVista_container::before {
    content: "";
    background-image: linear-gradient(to bottom, #25252530, #25252530);
    width: 100%;
    height: 84vh;
    position: absolute;
}

div.TerceraVista_container div.contenido {
    width: 30%;
    align-self: center;
    margin-left: 4%;
    padding: 1%;
    background-color: #00000040;
    border-radius: 5px;
    z-index: 10;
}

div.TerceraVista_container h1 {
    font-family: 'Montserrat';
    font-weight: 500;
    margin-bottom: 5%;
    color: #ffffff;
}

div.TerceraVista_container h3 {
    font-family: 'Montserrat';
    font-weight: 300;
    color: #ffffff;
}

div.TerceraVista_container a {
    Font-family: "Montserrat";
    font-size: 18px;
    font-weight: 500;
    color: #000000;
    background-color: #ffffff;
    border-style: solid;
    border-radius: 100px 100px 100px 100px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-color: #000000;
    cursor: pointer;
    width: 50%;
}

/*Responsive*/


@media only screen and (max-width: 992px) {
    div.TerceraVista_container {
        height: 100vh;
        background-position-y: 359vh;
        background-position-x: 210vw;
    }
    div.TerceraVista_container div.contenido {
        width: 45%;
    }

    div.TerceraVista_container::before {
        height: 100vh;
    }
}

@media only screen and (max-width: 768px) {
    div.TerceraVista_container {
        height: 100vh;
        background-position-y: 158vh;
        background-position-x: 196vw;
    }
    div.TerceraVista_container div.contenido {
        width: 45%;
    }
    div.TerceraVista_container h1, div.TerceraVista_container h3 {
        color: #fff;
    }
}

@media only screen and (max-width: 600px) {
    div.TerceraVista_container {
        height: 110vh;
        background-size: cover;
        background-position-x: 263vw;
        background-position-y: 110vh;
    }
    div.TerceraVista_container::before {
        height: 110vh;
    }
    div.TerceraVista_container div.contenido {
        width: 90%;
    }
}