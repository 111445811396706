div.servicios_container {
    background-image: url(https://wedrim.sfo2.cdn.digitaloceanspaces.com/media/SERVICIOS.jpg);
    display: flex;
    flex-direction: row;
    height: 95vh;
    width: 100%;
    justify-content: center;
}

div.servicios_container div.contenido {
    width: 90%;
    align-self: center;
    margin-top: 15%;
}

div.servicios_container h1 {
    font-family: 'Montserrat';
    font-weight: 500;
    margin-bottom: 2%;
    color: #ffffff;
}

div.servicios_container h3 {
    font-family: 'Montserrat';
    font-weight: 300;
    color: #ffffff;
}

div.servicios_container a {
    Font-family: "Montserrat";
    font-size: 18px;
    font-weight: 500;
    color: #000000;
    background-color: #ffffff;
    border-style: solid;
    border-radius: 100px 100px 100px 100px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-color: #000000;
    cursor: pointer;
    width: 100%;
    text-align: center;
}


div.servicios_container div.contenido div.container_tarjetas {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
}

div.servicios_container div.contenido div.container_tarjetas span.fila {
    display: flex;
    flex-direction: row;
    margin-bottom: 5%;
}

/*Tarjeta*/
div.tarjeta {
    padding: 1%;
    text-align: center;
    align-self: center;
}

div.tarjeta img {
    width: 20%;
}

div.tarjeta div.texto {
    text-align: center;
}

div.tarjeta div.texto p {
    font-family: 'Montserrat';
    font-weight: 300;
    color: #ffffff;
}




/*Responsive*/

@media only screen and (max-width: 992px) {
    div.servicios_container {
        height: 95vh;
        background-position-y: 306vh;
        background-position-x: 160vh;
    }

    div.tarjeta img {
        width: 50%;
    }

}

@media only screen and (max-width: 768px) {
    div.servicios_container {
        height: unset;
        background-position-y: 158vh;
        background-position-x: 196vw;
    }

    div.servicios_container div.contenido {
        margin-top: 30%;
    }
    

    div.servicios_container h1 {
        text-align: center;
    }

    div.servicios_container div.contenido div.container_tarjetas {
        display: flex;
        flex-direction: row;
    }

    div.servicios_container div.contenido div.container_tarjetas span.fila {
        display: flex;
        flex-direction: column;
    }

    div.tarjeta img {
        width: 25%;
    }

}

@media only screen and (max-width: 600px) {
    div.servicios_container {
        /* height: 170vh; */
        background-position-y: 170vh;
        overflow-y: overlay;
    }

    div.servicios_container div.contenido {
        width: 90%;
        text-align: center;
        align-self: normal;
        margin-top: 40%;
    }

    div.servicios_container h1 {
        margin-bottom: 10%;
    }

    div.servicios_container div.contenido div.container_tarjetas {
        display: flex;
        flex-direction: row;
        place-items: normal;
    }

    div.servicios_container div.contenido div.container_tarjetas span.fila {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        margin-bottom: 5%;
        justify-content: center;
    }

    div.tarjeta {
        margin-bottom: 10%;
    }
}