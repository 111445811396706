div.segundaVista_container {
    display: flex;
    flex-direction: row;
    height: 100vh;
    background-image: url(https://wedrim.sfo2.cdn.digitaloceanspaces.com/media/SOBRE_NOSOTROS_2.jpg);
    background-position-y: 262vh;
}

div.segundaVista_container div.contenido {
    width: 30%;
    align-self: center;
    margin-left: 4%;
    padding: 1%;
    background-color: #00000040;
    border-radius: 5px;
}

div.segundaVista_container h1 {
    font-family: 'Montserrat';
    font-weight: 500;
    margin-bottom: 2%;
    color: #ffffff;
}

div.segundaVista_container h3 {
    font-family: 'Montserrat';
    font-weight: 300;
    color: #ffffff;
}

div.segundaVista_container a {
    Font-family: "Montserrat";
    font-size: 18px;
    font-weight: 500;
    color: #000000;
    background-color: #ffffff;
    border-style: solid;
    border-radius: 100px 100px 100px 100px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-color: #000000;
    cursor: pointer;
    width: 100%;
    text-align: center;
}

div.segundaVista_container div.sobre_btn {
    display: flex;
    flex-direction: column;
    margin: 10% 0 0 2%;
    align-items: center;
}

div.sobre_btn h4 {
    font-family: 'Montserrat';
    font-weight: 500;
    margin-bottom: 20%;
}

/*Responsive*/

@media only screen and (max-width: 992px) {
    div.segundaVista_container {
        height: 100vh;
        background-position-y: 306vh;
        background-position-x: 200vw;
    }
    div.segundaVista_container div.contenido {
        width: 45%;
    }
}

@media only screen and (max-width: 768px) {
    div.segundaVista_container {
        height: 100vh;
        background-position-y: 158vh;
        background-position-x: 231vw;
    }
    div.segundaVista_container div.contenido {
        width: 45%;
    }
    div.segundaVista_container h1, div.segundaVista_container h3 {
        color: #fff;
    }
}


@media only screen and (max-width: 600px) {
    div.segundaVista_container {
        height: 100vh;
        background-position-y: 164vh;
        background-position-x: 370vw;
    }
    div.segundaVista_container div.contenido {
        width: 90%;
    }
}