/*Tarjetas*/

.cards {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 64vh;
    margin: 5% 0 5% 0;
}

.container_tarjetas {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
}

.tarjeta {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    width: 80%;
    /* bottom: unset; */
    background-color: #6699CC;
    padding-bottom: 3%;
}

div.container_tarjetas div.tarjeta_curso {
    width: 25%;
    display: contents;
}

.tarjeta img {
    width: 100%;
    max-width: 20vw;
}

.tarjeta p.tema_nota {
    font-family: "Montserrat";
    font-size: 15px;
    background-color: rgba(0, 0, 0, 0.5);
    color: #ffffff;
    position: absolute;
}

.tarjeta span.play {
    color: #ffffff;
    font-size: 30px;
    cursor: pointer;
    text-align: center;
}

.tarjeta .texto {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.tarjeta .texto span {
    color: #ffffff;
    font-size: 20px;
    margin-top: 2%;
    cursor: pointer;
}

.tarjeta .texto h3 {
    font-family: 'FreeSans';
    color: #ffffff;
    font-style: italic;
    font-size: 18px;
    margin-bottom: 2%;
    text-align: center;
}

.tarjeta .texto p {
    font-family: "Montserrat";
    font-size: 12px;
    color: #ffffff;
    text-align: center;
}

div.ver_mas {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

div.ver_mas a.ver_todos {
    font-size:25px; 
}

div.ver_mas h2 {
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 20px;
}

div.imagen_container {
    display: flex;
    justify-content: space-around;
}

/*Indice de notas*/

div.cards div.busqueda_notas {
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    margin-right: 13%;
    align-items: center;
}

div.busqueda_notas span.campo_boton input {
    padding: 12px 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 5%;
}

div.cards div.busqueda_notas a.ver_notas {
    font-family: "Montserrat";
    font-size: 15px;
    color: #000;
    text-align: center;
    cursor: pointer;
}

/*Responsive*/

@media only screen and (max-width: 992px) {
    .container_tarjetas {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-items: center;
        width: 100%;
    }
    div.card_descripcion {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 8%;
        width: 50%;
        justify-content: space-evenly;
    }
    .cards {
        display: flex;
        flex-direction: column;
        height: 75vh;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        background-size: 100%;
        background-repeat: no-repeat;
        background-position-y: 0vh;
        background-position-x: 2vw;
        margin: 5% 0 5% 0;
    }

    .tarjeta img {
        width: 100%;
        max-width: 40vw;
    }

    div.cards div.busqueda_notas {
        display: flex;
        flex-direction: column;
        align-self: center;
        margin-right: 0;
    }
}

@media only screen and (max-width: 768px) {
    .container_tarjetas {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-items: center;
        width: 100%;
    }
    div.card_descripcion {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 8%;
        width: 50%;
    }

    .tarjeta img {
        width: 100%;
        max-width: 40vw;
    }

    div.cards div.busqueda_notas {
        display: flex;
        flex-direction: column;
        align-items: center;
        align-self: center;
        padding: 15%;
        margin-right: 0;
    }
}

@media only screen and (max-width: 600px) {

    .tarjeta img {
        width: 100%;
        max-width: 100vw;
    }
    .cards {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        height: 170vh;
        margin-top: 5%;
    }
    .container_tarjetas {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        width: 100%;
    }
    .cards h2.titulo_accede {
        font-family: "FreeSansBoldOblique";
        color: #ffffff;
        background-color: #D80191;
        margin-bottom: 25%;
        text-align: center;
        width: 90%;
        border-radius: 25px;
    }
    div.card_descripcion {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 35%;
        width: auto;
    }
    div.card_descripcion div.favorito span.icono_favorito div.favorito_icono_texto {
        display: flex;
        justify-content: space-around;
        white-space: pre;
    }

    div.ver_mas {
        margin-top: 0;
    }

    div.cards div.busqueda_notas {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 0;
        align-self: center;
        padding: 15%;
    }
    

}