/*NavBar*/

nav.navbar {
  background: #111;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 11vh;
  padding: 3%;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 100;
}

nav.navbar a {
  width: 15%;
}

nav.navbar img {
  width: 100%;
}

nav.navbar h2 {
  margin-bottom: 0px;
  margin-top: 0px;
  float: left;
  margin-left: 7%;
}

div.navbar_btn {
  display: flex;
}

a.servicios {
  display: flex;
  align-items: flex-end;
  font-family: 'Montserrat';
  font-weight: 300;
  text-decoration: none;
  color: #ffffff;
  font-size: 20px;
  border: none;
  cursor: pointer;
}

a.contactos {
  display: flex;
  align-items: flex-end;
  font-family: 'Montserrat';
  font-weight: 300;
  text-decoration: none;
  color: #ffffff;
  font-size: 20px;
  border: none;
  cursor: pointer;
}

a.servicios .down_arrow, a.contactos .down_arrow {
  margin-left: 10px;
}

/*Dropdown servicios*/

div.navbar_btn ul li.lista_servicios ul.servicios_dropdown {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  background-color: grey;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  width: 11%;
}

div.navbar_btn ul li.lista_servicios ul.servicios_dropdown li.menu_dropdown {
  margin-left: 5%;
}

ul.servicios_dropdown {
  display: flex;
  flex-direction: column;
}

ul.servicios_dropdown a:hover {
  background-color: rgb(106, 103, 103);
}

div.navbar_btn ul li.lista_servicios:hover ul.servicios_dropdown {
  opacity: 1;
  visibility: visible;
  transition: visibility 0s, opacity 0.5s ease-in;
  justify-content: space-around;
}

/*Dropdown contacto*/

div.navbar_btn ul li.lista_contactos ul.contactos_dropdown {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  background-color: grey;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  width: 10%;
}

div.navbar_btn ul li.lista_contactos ul.contactos_dropdown li.menu_dropdown {
  margin-left: 5%;
}

ul.contactos_dropdown {
  display: flex;
  flex-direction: column;
}

ul.contactos_dropdown a:hover {
  background-color: rgb(106, 103, 103);
}

button.contactos:hover {
  text-decoration: underline;
}

div.navbar_btn ul li.lista_contactos:hover ul.contactos_dropdown {
  opacity: 1;
  visibility: visible;
  transition: visibility 0s, opacity 0.5s ease-in;
  justify-content: space-around;
}

ul.servicios_dropdown li.menu_dropdown a, ul.contactos_dropdown li.menu_dropdown a {
  font-size: 15px;
}

ul.servicios_dropdown li.menu_dropdown, ul.contactos_dropdown li.menu_dropdown {
  width: 100%;
}

ul.servicios_dropdown, ul.contactos_dropdown {
  height: 15vh;
  justify-content: space-around;
}

ul.contactos_dropdown {
  height: 10vh;
  justify-content: space-around;
}

div.navbar_btn .desplegable_visible {
  cursor: pointer;
  color: #ffffff;
}

div.navbar_btn ul {
  display: flex;
  justify-content: flex-end;
  /* align-items: center; */
}

div.navbar_btn ul li {
  list-style: none;
  margin-right: 22%;
}

a:link, a:visited, a:active {
  font-family: 'Montserrat';
  font-weight: 300;
  text-decoration: none;
  color: #fff;
  font-size: 20px;
}

div.navbar_btn button.idiomas {
  Font-family: "Montserrat";
  color: #ffffff;
  font-size: 20px;
  cursor: pointer;
}

div.menu_navegacion_desktop {
  position: fixed;
  padding: 2%;
  top: 0;
  right: 0;
  width: 20%;
  height: 80vh;
  background-color: #ddd;
}

div.menu_navegacion_desktop div.iconos_redes {
  align-self: center;
  width: 100%;
  margin-top: 5%;
}

div.iconos_redes div.contenido {
  display: flex;
  justify-content: space-evenly;
}

div.iconos_redes div.contenido .icono_redes {
  font-size: 30px;
  cursor: pointer;
}

div.menu_navegacion_desktop ul {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 90%;
  justify-content: space-evenly;
}

div.menu_navegacion_desktop ul li a {
  color: #000;
}

div.desplegable_btn {
  display: none;
}

/*Responsive*/

@media only screen and (max-width: 992px) {
  nav.navbar div.desplegable_btn {
    font-size: 40px;
    display: flex;
    color: #ffffff;
  }
  nav.navbar div.navbar_btn {
    display: none;
  }
  nav.navbar a {
    width: 40%;
    color: #000;
  }
  nav.navbar img {
    width: 70%;
  }
  div.menu_navegacion_desktop {
    position: fixed;
    padding: 2%;
    top: 0;
    right: 0;
    width: 20%;
    height: 80vh;
    background-color: #ddd;
  }
  div.menu_navegacion_desktop ul {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 90%;
    justify-content: space-evenly;
  }
  .menu_navegacion {
    flex-direction: column;
    position: fixed;
    padding: 2%;
    top: 0;
    left: 0;
    width: 100%;
    height: 70vh;
    background-color: #ddd;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .menu_navegacion ul li {
    margin: 2%;
    width: 100%;
    list-style: none;
  }

  div.menu_navegacion div.iconos_redes {
    align-self: center;
    width: 100%;
    margin: 5% 0 5% 0
  }
  
  div.menu_navegacion div.iconos_redes div.contenido {
    display: flex;
    justify-content: space-evenly;
  }
  
  div.menu_navegacion div.iconos_redes div.contenido .icono_redes {
    font-size: 25px;
    cursor: pointer;
    color: #000;
  }

  div.menu_navegacion button.idiomas {
    Font-family: "Montserrat";
    font-size: 18px;
    font-weight: 500;
    color: #000000;
    background-color: #ddd;
    border-style: solid;
    border-radius: 100px 100px 100px 100px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-color: #000000;
    cursor: pointer;
  }

  div.menu_navegacion div.iconos_redes div.contenido .icono_redes {
    font-size: 35px;
  }
}

@media only screen and (max-width: 768px) {
  nav.navbar div.desplegable_btn {
    font-size: 40px;
    display: flex;
    color: #ffffff;
  }
  nav.navbar a {
    width: 40%;
    color: #000;
  }
  nav.navbar img {
    width: 70%;
  }
  div.navbar_btn ul {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  div.navbar_btn ul li {
    margin-right: 5%;
  }
  nav.navbar div.navbar_btn {
    display: none;
  }
  nav.navbar div.desplegable_btn {
    font-size: 40px;
    display: flex;
  }
  a:link, a:visited, a:active {
    font-size: 18px;
  }
  nav.navbar div.navbar_btn ul .li_redes span.iconos_redes {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  .menu_navegacion {
    position: fixed;
    padding: 2%;
    top: 0;
    left: 0;
    width: 100%;
    height: 70vh;
    background-color: #ddd;
    overflow-x: hidden;
    overflow-y: auto;
  }
  a.cursos_nav_btn:link, a.cursos_nav_btn:visited, a.cursos_nav_btn:active {
    color: #000;
  }
  a.blog_nav_btn:link, a.blog_nav_btn:visited, a.blog_nav_btn:active {
    color: #000;
  }
  a.ingreso_registro:link, a.ingreso_registro:visited, a.ingreso_registro:active {
    color: #000;
  }
  .menu_navegacion ul {
    display: flex;
    flex-direction: column;
    height: 120vh;
    width: 90%;
    justify-content: space-evenly;
  }
  .menu_navegacion ul li {
    margin: 2%;
    width: 100%;
    list-style: none;
  }
  div.menu_navegacion button.idiomas {
    Font-family: "Montserrat";
    font-size: 18px;
    font-weight: 500;
    color: #000000;
    background-color: #ddd;
    border-style: solid;
    border-radius: 100px 100px 100px 100px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-color: #000000;
    cursor: pointer;
  }

  
}

@media only screen and (max-width: 600px) {
  nav.navbar a {
    width: 60%;
    color: #000
  }
  nav.navbar img {
    width: 100%;
  }
  nav.navbar div.navbar_btn {
    display: none;
  }
  .menu_navegacion {
    display: flex;
    flex-direction: column;
    position: fixed;
    padding: 2%;
    top: 0;
    left: 0;
    width: 100%;
    height: 70vh;
    background-color: #ddd;
  }
  a.cursos_nav_btn:link, a.cursos_nav_btn:visited, a.cursos_nav_btn:active {
    color: #000;
  }
  a.blog_nav_btn:link, a.blog_nav_btn:visited, a.blog_nav_btn:active {
    color: #000;
  }
  a.ingreso_registro:link, a.ingreso_registro:visited, a.ingreso_registro:active {
    color: #000;
  }
  .menu_navegacion ul {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 90%;
    justify-content: flex-start;
  }
  .menu_navegacion ul li {
    margin: 2%;
    width: 100%;
    list-style: none;
  }
  div.menu_navegacion button.idiomas {
    Font-family: "Montserrat";
    font-size: 18px;
    font-weight: 500;
    color: #000000;
    background-color: #ddd;
    border-style: solid;
    border-radius: 100px 100px 100px 100px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-color: #000000;
    cursor: pointer;
  }


  div.menu_navegacion div.iconos_redes {
    align-self: center;
    width: 100%;
    margin: 5% 0 5% 0
  }
  
  div.menu_navegacion div.iconos_redes div.contenido {
    display: flex;
    justify-content: space-evenly;
  }
  
  div.menu_navegacion div.iconos_redes div.contenido .icono_redes {
    font-size: 30px;
    cursor: pointer;
    color: #000;
  }
}