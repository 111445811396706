div.form_carga_container {
    height: 95vh;
    margin-left: 5%;
    display: flex;
    flex-direction: row;
}

div.form_carga_container form {
    display: flex;
    flex-direction: column;
}

div.form_carga_container form input {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
}

div.form_carga_container div.blog_container form.campos_blog {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 0;
}

div.form_carga_container div.cursos_container form.campos_cursos {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 0;
}

div.form_carga_container div.preguntas_container form.campos_preguntas {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 0;
}

div.form_carga_container h3.titulo {
    margin-bottom: 2%;
}

div.form_carga_container button.guardar {
    width: 20%;
    font-family: "FreeSans";
    font-size: 18px;
    padding: 10px 30px 10px 30px;
    background-color: #6699CC;
    border-radius: 10px;
    color: #ffffff;
    border: none;
    cursor: pointer;
    margin: 3% 0 3% 0;
}

div.form_carga_container div.cursos_container h3.titulo {
    margin-bottom: 2%;
}

div.form_carga_container div.blog_container {
    width: 60%;
}

div.form_carga_container div.indice_blog h1.admin_titulo {
    color: #6699CC;
    font-size: 50px;
}

/*Indice Notas*/

div.form_carga_container div.indice_blog {
    display: flex;
    flex-direction: column;
    align-self: center;
}

div.form_carga_container div.indice_blog button.buscar_btn {
    font-family: "FreeSans";
    font-size: 18px;
    padding: 10px 30px 10px 30px;
    background-color: #6699CC;
    border-radius: 10px;
    color: #ffffff;
    border: none;
    cursor: pointer;
    margin-left: 5%;
}

div.form_carga_container div.indice_blog button.nuevo_btn {
    font-family: "FreeSans";
    font-size: 18px;
    padding: 8px 20px 8px 20px;
    background-color: #6699CC;
    border-radius: 10px;
    color: #ffffff;
    border: none;
    cursor: pointer;
    margin-top: 5%;
}

div.form_carga_container div.indice_blog div.titulo_campos {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 15%;
}

div.form_carga_container div.indice_blog div.titulo_campos ul .trash_icon {
    font-size: 15px;
    color: #6699CC;
}

div.form_carga_container div.indice_blog div.titulo_campos ul .pen_icon {
    font-size: 15px;
    color: #d8007e;
}

div.form_carga_container div.indice_blog span.campo_boton {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

div.form_carga_container div.indice_blog input {
    width: 95%;
    padding: 10px 20px;
}

div.form_carga_container div.indice_blog span.titulo {
    display: flex;
    justify-content: center;
}

div.form_carga_container div.indice_blog h4.titulo_indice {
    font-family: 'FreeSansBold';
    color: #6699CC;
    font-size: 20px;
    margin-bottom: 5%;
}

div.form_carga_container div.indice_blog a.ver_notas {
    font-family: 'FreeSans';
    color: #6699CC;
    font-size: 15px;
    margin-top: 2%;
    cursor: pointer;
}

div.form_carga_container div.indice_blog div.titulo_campos ul {
    font-family: "FreeSansBold";
    color: #6699CC;
    list-style: none;
    cursor: pointer;
}

div.form_carga_container div.indice_blog div.titulo_campos ul a.cerrar_notas {
    font-family: 'FreeSansBold';
    color: #6699CC;
    font-size: 15px;
    margin-top: 2%;
    cursor: pointer;
}


/*Login admin*/

div.form_container {
    height: 95vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

div.form_container div.campos_formulario h2.titulo_form {
    margin-top: 10%;
    text-align: center;
}

div.formulario .campos_formulario {
    justify-content: center;
    align-self: flex-end;
}

.formulario form input {
    font-family: "FreeSans";
    font-size: 20px;
    color: #6699CC;
    border: none;
    border-bottom: solid;
    border-width: 0.5px 0.5px 0.5px 0.5px;
    margin: 4%;
    width: 100%;
}

.formulario form input#name, input#telefono {
    text-align: left;
    background-color: #ffffff;
    border-color: #BFBFBF;
    border-style: solid;
    border-width: 0.5px 0.5px 0.5px 0.5px;
    border-radius: 7px 7px 7px 7px;
    padding: 4%;
    width: 90%;
}

.formulario form textarea {
    text-align: left;
    background-color: #ffffff;
    border-color: #BFBFBF;
    border-style: solid;
    border-width: 0.5px 0.5px 0.5px 0.5px;
    border-radius: 7px 7px 7px 7px;
    padding: 4%;
    width: 90%;
}

form button.inicio_sesion {
    Font-family: "FreeSans";
    font-size: 18px;
    color: #6699CC;
    background-color: #ffffff;
    border-style: solid;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-color: #6699CC;
    cursor: pointer;
    margin-right: 3%;
    border-radius: 10px;
}

form button.registrarse {
    font-family: "FreeSans";
    font-size: 18px;
    padding: 10px 30px 10px 30px;
    background-color: #6699CC;
    border-radius: 10px;
    color: #ffffff;
    border: none;
    cursor: pointer;
    margin-left: 3%;
}

form {
    padding-left: 0;
}

form {
    list-style: none;
    margin-bottom: 7%;
}

span.form_buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
}

/*Pantalla Admin*/

div.botonesContainer {
    height: 95vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    place-content: center;
}

div.botonesContainer h4.titulo_container {
    align-self: center;
}

div.botonesContainer button {
    font-family: "FreeSans";
    font-size: 18px;
    padding: 10px 30px 10px 30px;
    background-color: #6699CC;
    border-radius: 10px;
    color: #ffffff;
    border: none;
    cursor: pointer;
    margin: 3% 0;
}

/*Responsive form Blog*/

@media only screen and (max-width: 992px) {}

@media only screen and (max-width: 768px) {}

@media only screen and (max-width: 600px) {
    div.form_carga_container {
        margin-top: 30%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    div.form_carga_container div.blog_container {
        width: 80%;
    }
    div.form_carga_container button.guardar {
        width: 100%;
    }
    div.form_carga_container div.indice_blog {
        width: 80%;
    }
    div.form_carga_container div.indice_blog h1.admin_titulo {
        text-align: center;
        font-size: 20px;
    }
    div.form_container {
        margin-top: 20%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    div.formulario .campos_formulario {
        justify-content: center;
        align-self: flex-end;
        width: 90%;
    }
    span.form_buttons {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
    }
    /*Pantalla Admin*/
    div.botonesContainer {
        display: flex;
        flex-direction: column;
        margin-top: 10%;
    }
    div.botonesContainer h4.titulo_container {
        align-self: center;
    }
    div.botonesContainer button {
        font-family: "FreeSans";
        font-size: 18px;
        padding: 10px;
        background-color: #6699CC;
        border-radius: 10px;
        color: #ffffff;
        border: none;
        cursor: pointer;
        margin: 3% 0;
    }
}