div.somos_container {
    display: flex;
    flex-direction: row;
    height: 95vh;
    
}    

div.somos_container video.video_principal {
    width: 100%;
    object-fit: cover;
}

div.somos_container div.contenido {
    width: 30%;
    align-self: center;
    margin-left: 4%;
    position: absolute;
}

div.somos_container h1 {
    font-family: 'Montserrat';
    font-weight: 500;
    margin-bottom: 5%;
    color: #fff;
}

div.somos_container a {
    Font-family: "Montserrat";
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    border-style: solid;
    border-radius: 100px 100px 100px 100px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-color: #fff;
    cursor: pointer;
    width: 50%;
}

  /*Responsive*/

  @media only screen and (max-width: 992px) {
    div.somos_container {
        height: 92vh;
    }
    div.somos_container div.contenido {
        width: 70%;
    }
  }

  @media only screen and (max-width: 768px) {
    div.somos_container {
        height: 92vh;
    }
    div.somos_container div.contenido {
        width: 70%;
    }
  }
  
  @media only screen and (max-width: 600px) {
    div.somos_container {
        height: 92vh;
    }
    div.somos_container div.contenido {
        width: 90%;
    }
  }